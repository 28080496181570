const downloadArrayOfObjectsAsCSV = (data, filename) => {
  if (!data || data.length === 0) {
    console.error('No data to download');
    return;
  }

  // Create CSV headers
  const headers = Object.keys(data[0]).join(',') + '\n';

  // Escape values and format rows
  const escapeValue = (value) => {
    if (value === null || value === undefined) {
      return '';
    }
    const stringValue = value.toString();
    return `"${stringValue.replace(/"/g, '""')}"`; // Escape double quotes
  };

  const rows = data
    .map((row) =>
      Object.values(row)
        .map((value) => escapeValue(value))
        .join(',')
    )
    .join('\n');

  const csvData = headers + rows;
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  // Handle download
  if (navigator.msSaveBlob) {
    // For IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }
};

export { downloadArrayOfObjectsAsCSV };
