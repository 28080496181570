import React, { useState } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Button } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  useGetRemarks,
  useAcknowledged,
} from '../../queries/reimbursementQuery';
import ColumnFilter from '../../components/common/ColumnFilter';
import {
  UpArrow,
  AcKnowledgeIcon,
  DisabledAcKnowledgeIcon,
} from '../../components/common/svgFiles';
import ReimbursementNavbar from '../../components/ReimbursementNavbar/ReimbursementNavbar';
import { useEffect } from 'react';
import { downloadArrayOfObjectsAsCSV } from '../../components/common/commonFunctions';
import { getRemarkForDoc } from '../../services/reimbursementClaims';

const Heading = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #714fff;
`;
const styleobj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#FFF',
  'font-weight': 'semi-bold',
  'font-family': 'Montserrat',
};
const colorMap = {
  0: 'badge-primary',
  1: 'badge-danger',
  2: 'badge-success',
  3: 'badge-warning',
  4: 'badge-info',
};
const LogoContainer = styled.img`
  max-width: 1.8rem;
`;
export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-dark.place-top {
    background-color: #714fff;
    padding: 0.3rem 1rem;

    &:after {
      border-top-color: blue;
    }
  }
`;

const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export default function QcRemarks() {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  let [pages, setCount] = useState(-1);
  const [rId, setReimbursementId] = useState('');
  const [intialDocName, setIntialDocName] = useState('');
  const [qcDocName, setQcDocName] = useState('');
  const toastTheme = 'dark';

  const {
    isLoading,
    data: getRemarks,
    error: errorIngetRemarks,
    isFetching,
    refetch: refetchQcRemarks,
  } = useGetRemarks(page, pageSize, rId, intialDocName, qcDocName);

  useEffect(() => {
    if (rId || intialDocName || qcDocName || page) {
      refetchQcRemarks();
    }
  }, [rId, intialDocName, qcDocName, page]);

  pages = getRemarks && Math.ceil(getRemarks.totalRecords / pageSize);

  const OnUpdateSuccess = () => {
    toast.success('Acknowledged..', { icon: '✅', theme: toastTheme });
    refetchQcRemarks();
  };
  const OnUpdateError = (error) => {
    toast.error(
      error.errorMessage || 'Something went wrong. Please try again later',
      { icon: '❌', theme: toastTheme }
    );
  };

  const { mutate: updateAcknowledged } = useAcknowledged(
    OnUpdateSuccess,
    OnUpdateError
  );

  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          minHeight: '70px',
          background: rowInfo.original.acknowledge ? '#F4F6FA' : '#ffff',
          color: 'red',
        },
      };
    }

    return {};
  };
  const onAcknowledgedBy = (reimbursementQcId) => () => {
    const acknowledge = true;
    updateAcknowledged({ acknowledge, reimbursementQcId });
  };

  const getColumns = () => [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px', textAlign: 'center' }}
        >
          Reimbursement ID
          <UpArrow />
        </div>
      ),
      accessor: 'reimbursementId',
      Cell: (row) => (
        <a
          href={`/reimburse-claim/${row.original.reimbursementId}`}
          target="_blank"
        >
          {row.original.reimbursementId}
        </a>
      ),
      filterable: true,
      Filter: ColumnFilter,
      width: 190,
      style: { padding: '10px', textAlign: 'center' },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
    },
    {
      Header: 'Reimbursement Claimed by',
      accessor: 'intialDocName',
      filterable: true,
      Filter: ColumnFilter,
      style: { textAlign: 'center' },
      width: 170,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
    },
    {
      Header: 'Qc Claimed By',
      accessor: 'qcDocName',
      filterable: true,
      width: 170,
      Filter: ColumnFilter,
      style: { textAlign: 'center' },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
    },
    {
      Header: 'Issue',
      accessor: 'declaredRemarks',
      width: 300,
      Cell: (row) => {
        return (
          <div className="flex flex-wrap ">
            {row.original.declaredRemarks.split(',').map((data, index) => {
              return (
                <span
                  className="mr-2 mb-1 p-1"
                  style={{
                    border: '1px solid #69686A',
                    color: row.original.acknowledge ? '#738BA9' : '#69686A',
                    borderRadius: '7px',
                    fontWeight: '600',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {data}
                </span>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: 'Comment',
      accessor: 'remark',
      // width: 100,
      style: { textAlign: 'center' },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
      Cell: (row) => <span>{`${row.original.remark || 'NA'}`}</span>,
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          QC Allotted on
          <UpArrow />
        </div>
      ),
      accessor: 'qcAllotedTime',
      width: 150,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
      Cell: (row) => (
        <span>
          {row.original.qcAllotedTime
            ? moment(row.original.qcAllotedTime).format('DD/MM/YYYY HH:mm')
            : null}
        </span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          QC Completed on
          <UpArrow />
        </div>
      ),
      accessor: 'qcCompletedTime',
      width: 150,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
      Cell: (row) => (
        <span>
          {row.original.qcCompletedTime
            ? moment(row.original.qcCompletedTime).format('DD/MM/YYYY HH:mm')
            : null}
        </span>
      ),
    },
    {
      Header: () => (
        <div className="flex flex-row">
          Insurer Status
          <UpArrow />
        </div>
      ),
      accessor: 'claimStatus',
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
      width: 130,
      // filterable: true,
      // Filter: ({ filter, onChange }) => {
      //     return (
      //         <select
      //             onChange={(event) => onChange(event.target.value)}
      //             // style={{ width: '100%' }}
      //             value={filter ? filter.value : 'all'}
      //         >
      //             <option value="all">Show All</option>
      //             <option value="settled">Settled</option>
      //             <option value="pending">Pending</option>
      //         </select>
      //     );
      // },
      Cell: (row) => {
        // eslint-disable-next-line default-case
        switch (row.original.claimStatus.toUpperCase()) {
          case 'PENDING':
            return (
              <div
                className=" flex flex-column mb-2"
                style={{
                  border: '2px solid #EC9719',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  style={{
                    backgroundColor: '#EC9719',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );

          case 'REJECTED':
          case 'FAILURE':
          case 'REVERSED':
            return (
              <div
                className="flex flex-column border-2 mb-2"
                style={{
                  border: '2px solid #D53815',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium"
                  style={{
                    backgroundColor: '#D53815',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );
          case 'APPROVED':
            return (
              <div
                className="flex flex-column mb-2"
                style={{
                  border: '2px solid #72BE11',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium"
                  style={{
                    backgroundColor: '#72BE11',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );

          case 'SETTLED':
            return (
              <div
                className="flex flex-column mb-2"
                style={{
                  border: '2px solid #3899D3',
                  borderRadius: '5px',
                  padding: '0px',
                }}
              >
                <span className="text-center"> Insurer</span>
                <button
                  type="button"
                  className="py-2 px-3 text-xs font-medium "
                  style={{
                    backgroundColor: '#3899D3',
                    color: '#fff',
                    padding: '5px',
                  }}
                >
                  {capitalizeFirst(row.original.claimStatus)}
                </button>
              </div>
            );
        }
      },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Claim Request date
          <UpArrow />
        </div>
      ),
      width: 250,
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            color:
              rowInfo && rowInfo.original.acknowledge ? '#738BA9' : '#3A3A49',
            fontWeight: '500',
          },
        };
      },
      accessor: 'createdAt',
      Cell: (row) => (
        <span>
          {row.original.createdAt
            ? moment(row.original.createdAt).format('DD/MM/YYYY HH:mm')
            : null}
        </span>
      ),
    },

    {
      Header: 'Actions',
      numeric: false,
      disablePadding: false,
      style: {
        whiteSpace: 'unset',
        position: 'absolute',
        right: 0,
        background: 'white',
        height: '100%',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Montserrat',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'absolute',
        right: 0,
        background: '#FFFFFF',
        textAlign: 'center',
      },
      Cell: (row) => {
        return (
          <div className="flex flex-row">
            <ReactTooltipStyled
              id="acknowledged"
              place="top"
              effect="float"
              type=""
            >
              AcKnowledge
            </ReactTooltipStyled>
            {row.original.acknowledge ? (
              <button
                className=""
                data-tip
                data-for="acknowledged"
                style={{
                  border: '2px solid #738BA9',
                  borderRadius: '8px',
                  padding: '2px',
                }}
                disabled={true}
              >
                <DisabledAcKnowledgeIcon />
              </button>
            ) : (
              <button
                className=""
                data-tip
                data-for="acknowledged"
                style={{
                  border: '2px solid #714FFF',
                  borderRadius: '8px',
                  padding: '2px',
                }}
                onClick={onAcknowledgedBy(row.original.qcId)}
              >
                <AcKnowledgeIcon />
              </button>
            )}
          </div>
        );
      },
    },
  ];
  const filterData = (state) => {
    console.log('filter---', state);
    const { filtered } = state;
    const idFilter =
      filtered && filtered.find((f) => f.id === 'reimbursementId');
    const intialDocName =
      filtered && filtered.find((f) => f.id === 'intialDocName');
    const qcDocName = filtered && filtered.find((f) => f.id === 'qcDocName');

    if (idFilter) {
      setReimbursementId(idFilter.value);
    }

    if (!idFilter) {
      setReimbursementId('');
    }
    if (intialDocName) {
      setIntialDocName(intialDocName.value);
    }
    if (qcDocName) {
      setQcDocName(qcDocName.value);
    }
  };

  const downloadCsvQrRemark = async () => {
    const params = {
      page,
      pageSize: 10000,
      reimbursementId: rId,
      intialDocName,
      qcDocName,
    };

    await getRemarkForDoc(params).then((result) => {
      const rawData = result?.data.map((data) => {
        return {
          reimbursementId: data.reimbursementId,
          claimStatus: data.claimStatus,
          declaredRemarks: data.declaredRemarks,
          approvedBy: data.qcDocName ? data.qcDocName : 'N.A',
          qcAllotedTime: moment(data.qcAllotedTime).format('DD-MM-YYYY'),
          remark: data.remark,
          requestStatus: data.requestStatus,
          acknowledge: data.acknowledge,
          createdAt: moment(data.createdAt).format('DD-MM-YYYY'),
          qcCompletedTime: data.qcCompletedTime
            ? moment(data.qcCompletedTime).format('DD-MM-YYYY')
            : 'N.A',
        };
      });

      return downloadArrayOfObjectsAsCSV(
        rawData,
        `Qc_Remarks_Report_${moment().format('YYYY-MM-DD')}`
      );
    });
  };

  return (
    <div
      className="container-fluid w-full px-0  flex flex-col primary-bg m-0 p-0"
      style={{ backgroundColor: '#F5F3F8' }}
    >
      {/* <div className='container-fluid w-full' style={{ backgroundColor: '#F5F3F8' }}> */}
      {/* 
            <ReimbursementNavbar
                onSearch={''}
                opsName={''}
                AddClaim={false}
            /> */}
      <div className="flex flex-row m-4 items-center justify-between">
        <Heading>QC Remarks</Heading>
        <Button
          className=" float-right custom-hover-button"
          style={{ border: '1px solid #714EFF' }}
          onClick={() => downloadCsvQrRemark()}
        >
          Download CSV
        </Button>
      </div>

      <div className="pt-1" style={{ width: '100%', overflow: 'hidden' }}>
        <ReactTable
          keyField="reimbursementId"
          data={getRemarks && getRemarks.data}
          pages={pages}
          manual
          defaultSorted={[
            {
              id: 'reimbursementId',
              desc: true,
            },
          ]}
          columns={getColumns()}
          showPaginationTop={false}
          useFlexLayout={true}
          showPageJump={false}
          defaultPageSize={20}
          loading={isLoading || isFetching}
          // style={styleobj}
          onFetchData={(state, instance) => {
            filterData(state);
            setPage(state.page);
            setPageSize(state.pageSize);
          }}
          getTrProps={getTrProps}
        />
      </div>
    </div>
  );
}
